import { Button, debounce, Grid, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SearchController } from 'types/Common';

const FragmentSearch = ({ onChange }: SearchController) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location.search));

  const [search, setSearch] = useState(params.search ?? '');
  const [types, setTypes] = useState<string[]>([]);
  const [queries, setQueries] = useState({ search });

  const handleClickReset = () => {
    setSearch('');
    setTypes([]);
    setQueries({ search: '' });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeParams = useCallback(
    debounce((values) => {
      setQueries((params) => ({ ...params, ...values }));
    }, 300),
    [],
  );

  useEffect(() => {
    onChange({
      ...queries,
      isActive: types.includes('isActive') ? 'true' : '',
    });
  }, [onChange, types, queries]);

  return (
    <Grid container spacing={3}>
      <Grid item sm={3}>
        <TextField
          fullWidth
          label='Search'
          placeholder='ID, Name...'
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
            debounceChangeParams({ search: event.target.value });
          }}
        />
      </Grid>

      <Grid item sm={4}>
        <ToggleButtonGroup color='info' value={types} onChange={(event, value) => setTypes(value)}>
          <ToggleButton value='isActive'>IS ACTIVE</ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      <Grid item sm={3}>
        <Button variant='outlined' className='w-28' onClick={handleClickReset}>
          Clear
        </Button>
      </Grid>
    </Grid>
  );
};

export default FragmentSearch;
