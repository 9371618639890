import { CollectionPaginateType, CollectionParamsType, CollectionType, CollectionUpdateType } from 'types/Collection';
import { FragmentPaginateType, FragmentParamsType, FragmentType, FragmentUpdateType } from 'types/Fragment';
import { ItemPaginateType, ItemParamsType, ItemType, ItemUpdateType } from 'types/Item';
import { UserPaginateType, UserParamsType, UserType, UserUpdateType } from 'types/User';
import { client } from './axios';

const fetchItems = (params: ItemParamsType): Promise<ItemPaginateType> => client.get(`/api/admin/item`, { params });
const getItemById = ({ id }: { id: string }): Promise<ItemType> => client.get(`/api/admin/item/${id}`);
const updateItem = ({ id, ...body }: ItemUpdateType): Promise<ItemType> => client.put(`/api/admin/item/${id}`, body);

const fetchFragments = (params: FragmentParamsType): Promise<FragmentPaginateType> =>
  client.get(`/api/admin/fragment`, { params });
const getFragmentById = ({ id }: { id: string }): Promise<FragmentType> => client.get(`/api/admin/fragment/${id}`);
const updateFragment = ({ id, ...body }: FragmentUpdateType): Promise<FragmentType> =>
  client.put(`/api/admin/fragment/${id}`, body);

const fetchCollections = (params: CollectionParamsType): Promise<CollectionPaginateType> =>
  client.get(`/api/admin/collection`, { params });
const getCollectionById = ({ id }: { id: string }): Promise<CollectionType> =>
  client.get(`/api/admin/collection/${id}`);
const updateCollection = ({ id, ...body }: CollectionUpdateType): Promise<CollectionType> =>
  client.put(`/api/admin/collection/${id}`, body);

const fetchUsers = (params: UserParamsType): Promise<UserPaginateType> => client.get(`/api/admin/user`, { params });
const getUserById = ({ id }: { id: string }): Promise<UserType> => client.get(`/api/admin/user/${id}`);
const updateUser = ({ id, ...body }: UserUpdateType): Promise<UserType> => client.put(`/api/admin/user/${id}`, body);

export default {
  fetchUsers,
  getUserById,
  updateUser,

  fetchFragments,
  getFragmentById,
  updateFragment,

  fetchCollections,
  getCollectionById,
  updateCollection,

  fetchItems,
  getItemById,
  updateItem,
};
