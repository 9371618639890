import { CollectionList, CollectionView } from 'views/Collection';
import { FragmentList, FragmentView } from 'views/Fragment';
import { Home } from 'views/Home';
import { ItemList, ItemView } from 'views/Item';
import { UserList, UserView } from 'views/User';

type RouteType = {
  path: string;
  url?: (query: any) => string;
  name?: string;
  element: JSX.Element;
};

type PrivateRouteType = {
  [key: string]: RouteType;
};

const privateRoute: PrivateRouteType = {
  home: {
    path: `/dashboard`,
    name: `Dashboard`,
    element: <Home />,
  },
  itemList: {
    path: `/items`,
    name: `Items`,
    element: <ItemList />,
  },
  itemView: {
    path: `/items/:id`,
    url: ({ id }) => `/items/${id}`,
    element: <ItemView />,
  },
  fragmentList: {
    path: `/fragments`,
    name: `Fragments`,
    element: <FragmentList />,
  },
  fragmentView: {
    path: `/fragments/:id`,
    url: ({ id }) => `/fragments/${id}`,
    element: <FragmentView />,
  },
  collectionList: {
    path: `/collections`,
    name: `Collections`,
    element: <CollectionList />,
  },
  collectionView: {
    path: `/collections/:id`,
    url: ({ id }) => `/collections/${id}`,
    element: <CollectionView />,
  },
  userList: {
    path: `/users`,
    name: `Users`,
    element: <UserList />,
  },
  userView: {
    path: `/users/:id`,
    url: ({ id }) => `/users/${id}`,
    element: <UserView />,
  },
};

export default privateRoute;
