import { LoadingButton } from '@mui/lab';
import { CardMedia, Container, Grid, Switch, TextField, Typography } from '@mui/material';
import { InputNumber } from 'components';
import { Fragment } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { dashboardService, queryClient } from 'services';
import { CollectionUpdateType } from 'types/Collection';

const CollectionView = () => {
  const { id } = useParams();
  const { control, handleSubmit } = useForm();

  const { data: collection, isFetched } = useQuery(['dashboardService.getCollectionById', id], () =>
    dashboardService.getCollectionById({ id: id! }),
  );

  const { mutate: updateItem, isLoading } = useMutation(dashboardService.updateCollection, {
    onSuccess: () => {
      queryClient.invalidateQueries('dashboardService.fetchCollections');
      queryClient.invalidateQueries('dashboardService.getCollectionById');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updateItem({
        ...(values as CollectionUpdateType),
        id: id!,
      });
    })();
  };

  if (!isFetched) return null;
  return (
    <Container>
      <div className='flex items-start'>
        <Grid container spacing={2} className='flex-1'>
          {[{ label: 'ID', value: collection?.id }].map((item, index) => (
            <Fragment key={index}>
              <Grid item sm={3.5}>
                <Typography color='textSecondary'>{item.label}</Typography>
              </Grid>
              <Grid item sm={8} className='h-12'>
                <Typography className='font-medium'>: {item.value}</Typography>
              </Grid>
            </Fragment>
          ))}

          <Grid item sm={3.5}>
            <Typography color='textSecondary'>Name</Typography>
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='name'
              defaultValue={collection?.name}
              control={control}
              render={({ field }) => <TextField fullWidth size='small' {...field} />}
            />
          </Grid>

          <Grid item sm={3.5}>
            <Typography color='textSecondary'>Slug</Typography>
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='slug'
              defaultValue={collection?.slug}
              control={control}
              render={({ field }) => <TextField fullWidth size='small' {...field} />}
            />
          </Grid>

          <Grid item sm={3.5}>
            <Typography color='textSecondary'>Sort Score</Typography>
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='sortScore'
              defaultValue={collection?.sortScore}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size='small'
                  fullWidth
                  InputProps={{
                    inputComponent: InputNumber as any,
                    inputProps: {
                      decimalScale: 0,
                      maxLength: 2,
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item sm={3.5}>
            <Typography color='textSecondary'>Is Active</Typography>
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='isActive'
              defaultValue={collection?.isActive}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Switch checked={value} onChange={(event, value) => onChange({ target: { value } })} />
              )}
            />
          </Grid>
        </Grid>

        <CardMedia image={collection?.logo} style={{ width: 320, height: 320 }} className='rounded-xl border' />
      </div>

      <div className='flex gap-6 mt-6'>
        <LoadingButton
          variant='contained'
          color='secondary'
          className='w-32'
          loading={isLoading}
          onClick={handleClickSubmit}
        >
          Save
        </LoadingButton>
      </div>
    </Container>
  );
};

export default CollectionView;
