import { CategoryOutlined, PanoramaOutlined, SupervisedUserCircleOutlined, WindowOutlined } from '@mui/icons-material';
import { Grid, Paper, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useQuery } from 'react-query';
import { dashboardService } from 'services';

const Home = () => {
  const { data: items } = useQuery(['dashboardService.fetchItems'], () => dashboardService.fetchItems({} as any));
  const { data: itemsActive } = useQuery(['dashboardService.fetchItems', { status: 1 }], () =>
    dashboardService.fetchItems({ status: 1 } as any),
  );

  const { data: fragments } = useQuery(['dashboardService.fetchFragments'], () =>
    dashboardService.fetchFragments({} as any),
  );
  const { data: fragmentsActive } = useQuery(['dashboardService.fetchFragments', { isActive: 'true' }], () =>
    dashboardService.fetchFragments({ isActive: 'true' } as any),
  );

  const { data: collections } = useQuery(['dashboardService.fetchCollections'], () =>
    dashboardService.fetchCollections({} as any),
  );
  const { data: collectionsActive } = useQuery(['dashboardService.fetchCollections', { isActive: 'true' }], () =>
    dashboardService.fetchCollections({ isActive: 'true' } as any),
  );

  const { data: users } = useQuery(['dashboardService.fetchUsers'], () => dashboardService.fetchUsers({} as any));
  const { data: usersAdmin } = useQuery(['dashboardService.fetchUsers', { isAdmin: 'true' }], () =>
    dashboardService.fetchUsers({ isAdmin: 'true' } as any),
  );
  const { data: usersMinter } = useQuery(['dashboardService.fetchUsers', { isMinter: 'true' }], () =>
    dashboardService.fetchUsers({ isMinter: 'true' } as any),
  );

  const data = [
    {
      label: 'Items',
      value: items?.total,
      icon: <PanoramaOutlined fontSize='large' />,
      infos: [{ label: 'Active', value: itemsActive?.total }],
    },
    {
      label: 'Fragments',
      value: fragments?.total,
      icon: <WindowOutlined fontSize='large' />,
      infos: [{ label: 'Active', value: fragmentsActive?.total }],
    },
    {
      label: 'Collections',
      value: collections?.total,
      icon: <CategoryOutlined fontSize='large' />,
      infos: [{ label: 'Active', value: collectionsActive?.total }],
    },
    {
      label: 'Users',
      value: users?.total,
      icon: <SupervisedUserCircleOutlined fontSize='large' />,
      infos: [
        { label: 'Admin', value: usersAdmin?.total },
        { label: 'Minter', value: usersMinter?.total },
      ],
    },
  ];

  return (
    <Grid container spacing={4}>
      {data.map((item, index) => (
        <Grid item key={index} sm={3}>
          <Paper className='h-full p-4 rounded-xl'>
            <Typography variant='h5' className='flex justify-between'>
              <span className='text-secondary-main'>{item.label}</span>
              {item.icon}
            </Typography>
            <Typography variant='h4' className='font-medium'>
              {item.value}
            </Typography>
            <Grid container spacing={1} className='mt-4'>
              {item.infos.map((item, index) => (
                <Fragment key={index}>
                  <Grid item sm={3}>
                    {item.label}
                  </Grid>
                  <Grid item sm={9}>
                    : {item.value}
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default Home;
