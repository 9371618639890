import { Add, Remove } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Container, FormControl, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { dashboardService, queryClient } from 'services';

const ItemMetadata = () => {
  const { id } = useParams();
  const { control, handleSubmit, setValue, resetField } = useForm();

  const [array, setArray] = useState([false]);

  useQuery(['dashboardService.getItemById', id], () => dashboardService.getItemById({ id: id! }), {
    onSuccess: (item) => {
      setArray([]);
      Object.entries(item.attributes ?? {}).forEach(([key, value], index) => {
        setArray((array) => array.concat(true));
        setValue(`key-${index}`, key);
        setValue(`value-${index}`, value);
      });
    },
  });

  const { mutate: updateItem, isLoading } = useMutation(dashboardService.updateItem, {
    onSuccess: () => {
      queryClient.invalidateQueries('dashboardService.getItemById');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      const attributes = Object.keys(values)
        .filter((key) => key.startsWith('key-') && values[key])
        .reduce((sum, key) => ({ ...sum, [values[key]]: values[key.replace('key', 'value')] }), {});

      updateItem({
        attributes,
        id: id!,
      });
    })();
  };

  return (
    <Container className='mt-10'>
      <div className='flex items-end gap-4 mb-3'>
        <Typography variant='h5'>Attributes</Typography>
        <Button
          variant='outlined'
          color='secondary'
          size='small'
          onClick={() => setArray((array) => array.concat(true))}
        >
          <Add />
        </Button>
      </div>

      {array.map((visible, index) =>
        visible ? (
          <div key={index} className='flex mb-4'>
            <Controller
              name={`key-${index}`}
              defaultValue=''
              control={control}
              rules={{ required: array[index] }}
              render={({ field, fieldState: { invalid } }) => (
                <FormControl>
                  <TextField
                    {...field}
                    size='small'
                    placeholder='Key'
                    InputProps={{
                      style: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
                    }}
                    error={invalid}
                  />
                </FormControl>
              )}
            />
            <Controller
              name={`value-${index}`}
              defaultValue=''
              control={control}
              rules={{ required: array[index] }}
              render={({ field, fieldState: { invalid } }) => (
                <FormControl>
                  <TextField
                    {...field}
                    size='small'
                    placeholder='Value'
                    InputProps={{
                      style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
                    }}
                    error={invalid}
                  />
                </FormControl>
              )}
            />

            <Button
              variant='outlined'
              color='error'
              className='ml-4'
              onClick={() =>
                setArray((array) => {
                  resetField(`key-${index}`);
                  array[index] = false;
                  return [...array];
                })
              }
            >
              <Remove />
            </Button>
          </div>
        ) : null,
      )}

      <div className='flex gap-6 mt-6'>
        <LoadingButton
          variant='contained'
          color='secondary'
          className='w-32'
          loading={isLoading}
          onClick={handleClickSubmit}
        >
          Save
        </LoadingButton>
      </div>
    </Container>
  );
};

export default ItemMetadata;
