import { Edit } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Pagination,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { dashboardService } from 'services';
import { FragmentSearch } from './components';

const FragmentList = () => {
  const [dataSearch, onSearchChange] = useSearch();

  const { data, isFetching } = useQuery(
    ['dashboardService.fetchFragments', dataSearch],
    () => dashboardService.fetchFragments(dataSearch),
    { keepPreviousData: true },
  );
  const { items = [], total, currentPage, pages: totalPage } = data ?? {};

  return (
    <>
      <FragmentSearch onChange={onSearchChange} />

      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Sort Score</TableCell>
                <TableCell>Is Active</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className='flex gap-3'>
                    <Avatar variant='rounded' className='w-20 h-14' src={item.image} />
                    <div>
                      <Typography variant='h6'>{item.name}</Typography>
                      <Typography>ID: {item.id}</Typography>
                    </div>
                  </TableCell>
                  <TableCell className='text-center'>
                    {item.sizeX} x {item.sizeY}
                  </TableCell>
                  <TableCell className='text-center'>{item.sortScore}</TableCell>
                  <TableCell className='text-center'>
                    <Switch checked={item.isActive} />
                  </TableCell>
                  <TableCell className='text-right'>
                    <Link to={privateRoute.fragmentView.url?.(item)!}>
                      <Button title='Edit'>
                        <Edit />
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && items.length === 0} />
            </TableBody>
            <caption className='font-bold border-t'>{total ?? 0} Fragments</caption>
          </Table>
        </Spinner>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>
    </>
  );
};

export default FragmentList;
