import { LoadingButton } from '@mui/lab';
import { CardMedia, Container, Grid, MenuItem, Switch, TextField, Typography } from '@mui/material';
import { InputNumber } from 'components';
import { Fragment } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { dashboardService, queryClient } from 'services';
import { ItemUpdateType } from 'types/Item';
import { ItemMetadata } from '.';

const STATUS_TYPES = [
  { label: 'Available', value: 1 },
  { label: 'Unavailable', value: 2 },
];

const ItemView = () => {
  const { id } = useParams();
  const { control, handleSubmit } = useForm();

  const { data: item, isFetched } = useQuery(['dashboardService.getItemById', id], () =>
    dashboardService.getItemById({ id: id! }),
  );

  const { mutate: updateItem, isLoading } = useMutation(dashboardService.updateItem, {
    onSuccess: () => {
      queryClient.invalidateQueries('dashboardService.fetchItems');
      queryClient.invalidateQueries('dashboardService.getItemById');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updateItem({
        ...(values as ItemUpdateType),
        id: id!,
      });
    })();
  };

  if (!isFetched) return null;
  return (
    <>
      <Container>
        <div className='flex items-start'>
          <Grid container spacing={2} className='flex-1'>
            {[
              { label: 'ID', value: item?.id },
              { label: 'TokenID', value: item?.tokenId },
            ].map((item, index) => (
              <Fragment key={index}>
                <Grid item sm={3.5}>
                  <Typography color='textSecondary'>{item.label}</Typography>
                </Grid>
                <Grid item sm={8} className='h-12'>
                  <Typography className='font-medium'>: {item.value}</Typography>
                </Grid>
              </Fragment>
            ))}

            <Grid item sm={3.5}>
              <Typography color='textSecondary'>Name</Typography>
            </Grid>
            <Grid item sm={6}>
              <Controller
                name='name'
                defaultValue={item?.name}
                control={control}
                render={({ field }) => <TextField fullWidth size='small' {...field} />}
              />
            </Grid>

            <Grid item sm={3.5}>
              <Typography color='textSecondary'>Status</Typography>
            </Grid>
            <Grid item sm={6}>
              <Controller
                name='status'
                defaultValue={item?.status}
                control={control}
                render={({ field }) => (
                  <TextField select fullWidth size='small' {...field}>
                    {STATUS_TYPES.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item sm={3.5}>
              <Typography color='textSecondary'>Sort Score</Typography>
            </Grid>
            <Grid item sm={6}>
              <Controller
                name='sortScore'
                defaultValue={item?.sortScore}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size='small'
                    fullWidth
                    InputProps={{
                      inputComponent: InputNumber as any,
                      inputProps: {
                        decimalScale: 0,
                        maxLength: 2,
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={3.5}>
              <Typography color='textSecondary'>Is Feature</Typography>
            </Grid>
            <Grid item sm={6}>
              <Controller
                name='isFeature'
                defaultValue={item?.isFeature}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch checked={value} onChange={(event, value) => onChange({ target: { value } })} />
                )}
              />
            </Grid>

            <Grid item sm={3.5}>
              <Typography color='textSecondary'>Is Banner</Typography>
            </Grid>
            <Grid item sm={6}>
              <Controller
                name='isInBanner'
                defaultValue={item?.isInBanner}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch checked={value} onChange={(event, value) => onChange({ target: { value } })} />
                )}
              />
            </Grid>

            <Grid item sm={3.5}>
              <Typography color='textSecondary'>Is Auction</Typography>
            </Grid>
            <Grid item sm={6}>
              <Controller
                name='isLiveAuction'
                defaultValue={item?.isLiveAuction}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch checked={value} onChange={(event, value) => onChange({ target: { value } })} />
                )}
              />
            </Grid>
          </Grid>

          <CardMedia image={item?.image} style={{ width: 320, height: 400 }} className='rounded-xl border' />
        </div>

        <div className='flex gap-6 mt-6'>
          <LoadingButton
            variant='contained'
            color='secondary'
            className='w-32'
            loading={isLoading}
            onClick={handleClickSubmit}
          >
            Save
          </LoadingButton>
        </div>
      </Container>

      <ItemMetadata />
    </>
  );
};

export default ItemView;
